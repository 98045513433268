import {
  type InferOutput,
  array,
  boolean,
  enum_,
  nullish,
  number,
  object,
  optional,
  picklist,
  string,
} from "valibot"

import { PortfolioType } from "./stocks-portfolios"

export const MomentumStatus = {
  UP: "UP",
  DOWN: "DOWN",
  EVEN: "EVEN",
} as const

export const AssetType = {
  STOCK: "S",
  ETF: "E",
  INDEX: "I",
  CRYPTO: "C",
  CURRENCY: "F",
  COMMODITY: "M",
} as const

export const Rating = {
  STRONG_BUY: "STRONG_BUY",
  BUY: "BUY",
  HOLD: "HOLD",
  SALE: "SALE",
  STRONG_SALE: "STRONG_SALE",
} as const

export const SectorId = {
  O: "O", // temp
  COMM: "COMM",
  COND: "COND",
  ENG: "ENG",
  FIN: "FIN",
  HC: "HC",
  IND: "IND",
  MAT: "MAT",
  SERV: "SERV",
  UTIL: "UTIL",
  RE: "RE",
  SACS: "SACS",
  TELCO: "TELCO",
  MEDIC: "MEDIC",
  PHARM: "PHARM",
  BANK: "BANK",
  INSUR: "INSUR",
  AUTO: "AUTO",
  RETAIL: "RETAIL",
  TRAVEL: "TRAVEL",
  CONSMAT: "CONSMAT",
  AEROSPACE: "AEROSPACE",
  ELECTRONIC: "ELECTRONIC",
  ENGEINEER: "ENGEINEER",
  SUPPORT: "SUPPORT",
  TRANSPOT: "TRANSPOT",
  ALTERNATIV: "ALTERNATIV",
  CONS: "CONS",
  IT: "IT",
  GOV: "GOV",
  HOLD: "HOLD",
  SPAC: "SPAC",
} as const

export const chartDataSchema = object({
  tradeDate: number(),
  price: number(),
})
export type ChartData = InferOutput<typeof chartDataSchema>

export const baseStockDataSchema = object({
  id: nullish(string()),
  logo: nullish(string()),
  description: nullish(string()),
  symbol: nullish(string()),
  price: nullish(number()),
  priceChange: nullish(number()),
  rankOrder: nullish(number()),
  momentum: nullish(number()),
  momentumStatus: nullish(enum_(MomentumStatus)),
  sector: enum_(SectorId),
  assetType: nullish(enum_(AssetType)),
  rating: nullish(enum_(Rating)),
  ratingDescriptionHe: nullish(string()),
  ratingDescriptionEn: nullish(string()),
  locked: optional(boolean()),
})

export const stockDataSchema = object({
  ...baseStockDataSchema.entries,
  ...object({
    chart: optional(array(chartDataSchema)),
  }).entries,
})

export type StockData = InferOutput<typeof stockDataSchema>

export const stockSectorSchema = object({
  id: string(),
  nameHe: string(),
  nameEn: string(),
  textHe: optional(string()),
  textEn: optional(string()),
  uiOrder: number(),
  imagePath: string(),
})
export type StockSector = InferOutput<typeof stockSectorSchema>

export const stockSectorTranslationSchema = object({
  descriptionHe: string(),
  descriptionEn: string(),
})
export type StockSectorTranslation = InferOutput<typeof stockSectorTranslationSchema>

export const tradedInPortfoliosSchema = picklist([
  PortfolioType.Long,
  PortfolioType.Short,
  PortfolioType.LongShort,
])

export type TradedInPortfolios = InferOutput<typeof tradedInPortfoliosSchema>

export const stockByIdSchema = object({
  ...baseStockDataSchema.entries,
  ...object({
    companyDescriptionEn: nullish(string()),
    companyDescriptionHe: nullish(string()),
    sectorNameHe: nullish(string()),
    sectorNameEn: nullish(string()),
    sectorIndustryNameHe: nullish(string()),
    sectorIndustryNameEn: nullish(string()),
    sectorHe: nullish(string()),
    marketCap: nullish(number()),
    pe: nullish(number()),
    peCurrent: nullish(number()),
    pe5YearsAvg: nullish(number()),
    pb: nullish(number()),
    pb5YearsClose: nullish(number()),
    ps: nullish(number()), // Changed from z.any() to nullish(number()) because z.any() doesn’t have a direct equivalent in Valibot
    psCurrent: nullish(number()),
    priceTarget: nullish(number()),
    numberOfAnalysts: nullish(number()),
    positiveAnalyst: nullish(number()),
    stocksInterests: nullish(array(stockDataSchema)),
    tradedInPortfolios: nullish(array(tradedInPortfoliosSchema)),
  }).entries,
})

export type StockById = InferOutput<typeof stockByIdSchema>

export const stockArticleSchema = object({
  logoPath: nullish(string()),
  title: string(),
  url: string(),
  date: number(),
  siteName: string(),
  sentiment: string(),
  sentimentSymbol: string(),
})
export type StockArticle = InferOutput<typeof stockArticleSchema>

export const stockYieldSchema = object({
  price: number(),
  tradeDate: number(),
})
export type StockYield = InferOutput<typeof stockYieldSchema>

export const stockRankingSchema = object({
  date: nullish(number()),
  assetOrder: nullish(number()),
  professionalWisdom: nullish(number()),
  crowdWisdom: nullish(number()),
  fundamentals: nullish(number()),
})
export type StockRanking = InferOutput<typeof stockRankingSchema>

export const stockComparisonChartSchema = object({
  lastUpdateDate: number(),
  charts: array(
    object({
      id: string(),
      datasets: array(
        object({
          date: number(),
          yield: number(),
        })
      ),
    })
  ),
})
export type StockComparisonChart = InferOutput<typeof stockComparisonChartSchema>

export const stocksAllocationParamsSchema = object({
  portfolioType: picklist(Object.values(PortfolioType)),
  yieldPeriod: optional(string()),
  index: optional(number()),
  size: optional(number()),
})
export type StocksAllocationParams = InferOutput<typeof stocksAllocationParamsSchema>

export const stocksAllocationSchema = object({
  portfolioId: string(),
  quantity: number(),
  price: number(),
  assetAllocation: number(),
  asset: object({
    id: string(),
    logo: nullish(string()),
    description: string(),
    symbol: string(),
    rankOrder: number(),
    priceChange: nullish(number()),
  }),
})
export type StocksAllocation = InferOutput<typeof stocksAllocationSchema>

export const stocksTransactionsBaseSchema = object({
  portfolioId: string(),
  assetId: string(),
  quantity: number(),
  quantityDisplay: nullish(number()),
  price: number(),
  profitLoss: nullish(number()),
  transactionDate: number(),
  transactionType: string(),
  transactionTypeDisplay: nullish(string()),
})

export const stocksTransactionsSchema = object({
  ...stocksTransactionsBaseSchema.entries,
  asset: object({
    id: string(),
    logo: nullish(string()),
    description: string(),
    symbol: string(),
    rankOrder: number(),
  }),
  buyTransaction: optional(stocksTransactionsBaseSchema),
})

export type StocksTransaction = InferOutput<typeof stocksTransactionsSchema>
